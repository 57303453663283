import React, { useEffect, useState } from "react"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { ProjectDetailsHeadline } from "components/pages/development/project/details/headline"
import { ProjectDetailsInfrastructure } from "components/pages/development/project/details/infrastructure"
import { ProjectDetailsGallery } from "components/pages/development/project/details/gallery"
import { ProjectDetailsTenants } from "components/pages/development/project/details/tenants"
import { YoutubeFrame } from "components/global/youtube-frame/youtube-frame"
import { ProjectDetailsLocation } from "components/pages/development/project/details/location"
import { ProjectDetailsNearby } from "components/pages/development/project/details/nearby"
import { GlobalMarketingGallery } from "components/global/marketing-gallery/marketing-gallery"
import { ProjectDetailsLeadForm } from "components/pages/development/project/details/lead-form"
import { NavBottom } from "components/anti/nav-bottom/nav-bottom"
import { Map } from "components/anti/map/map"
import { ProjectDetailsVirtualTour } from "components/pages/development/project/details/virtual-tour"
import ProjectDetailsDataProject from "../components/pages/development/project/details/data-project"

import { WithLocation } from "components/anti/utils/utils"
import MicrodataProject from "components/microdata-project";
import {Helmet} from "react-helmet";


const ProjectDetails = ({ pageContext, location, search }) => {
	// const dataNearby = useStaticQuery(GET_NEARBY)
	const lang = pageContext.langKey
	const redirectAmpHtml = process.env.GATSBY_ROOT_URL + location.pathname
	const [coverBottom, setCoverBottom] = useState({})

	const projectLocations = pageContext?.data?.projectLocations?.nodes[0]?.name
	const projectCategories = pageContext?.data?.projectCategories?.nodes[0]?.name

	const dataLang = lang === "en"
		? pageContext?.data
		: pageContext.data?.translation
		|| pageContext?.data

	const data = dataLang?.projectDetailsPage
	// const navBottomData = lang === "en"
	//     ? pageContext.data.projectDetailsPage.navBottom
	//     : pageContext.data?.translation?.projectDetailsPage?.navBottom
	//     || pageContext.data.projectDetailsPage.navBottom

	const eBrochures = data?.eBrochures

	const nearbyData = pageContext?.data?.projectLocations?.nodes[0]?.projects?.nodes

	const { seo } = lang === "en"
		? pageContext?.data
		: pageContext?.data?.translation
		|| pageContext?.data

	const slug = pageContext?.data?.slug
	const SEODefaultImg = data?.cover?.image?.sourceUrl

	const schemaMarkup = {}

	useEffect(() => {
		let pathSection = location?.pathname.split('/')[3]
		let projectIdx = pageContext?.projects.findIndex(el => el.slug === pathSection)
		const prevProj = pageContext?.projects[projectIdx - 1 < 0 ? pageContext?.projects?.length - 1 : projectIdx - 1]
		const nextProj = pageContext?.projects[projectIdx + 1 > pageContext?.projects?.length - 1 ? 0 : projectIdx + 1]

		setCoverBottom({
			prev: {
				path: `/development/${prevProj.category}/${prevProj.slug}`,
				image: prevProj?.image?.sourceUrl, // change this with image cover
				title: prevProj?.name,
				slug: prevProj?.slug,
			},
			next: {
				path: `/development/${nextProj.category}/${nextProj.slug}`,
				image: nextProj?.image?.sourceUrl, // change this with image cover
				title: nextProj?.name,
				slug: nextProj?.slug,
			}
		})
	}, [])

	const longitude = data?.location?.map?.longitude
	const latitude = data?.location?.map?.latitude
	const iframeMapScript = data?.location?.map?.iframeScript
	const bgColor = dataLang?.backgroundColor?.backgroundColor?.color
	const utmId = search?.utm_id ? search?.utm_id : ""
	const datalayerAdd = data?.dataLayer

	const dataLayerValue = () => {
		const value = {
			'event': 'ViewContent',
			'page_url': window.location.href, // entire string of current page URL including UTM parameter and hash # if any
			'page_title': data.cover?.title,
			'content_type': 'home_listing',
			'content_id': slug, // any IDs in your listing catalog, example: 'Provence Suites'
			'city': projectLocations, // provide the user's city of the interest, example 'BSD City'
			'region': projectLocations, // state, district, or region of interest, example 'South Tangerang'
			'country': 'Indonesia', // target country of the interest, example 'Indonesia'
			'lease_start_date': datalayerAdd?.leaseStartDate,
			'lease_end_date': datalayerAdd?.leaseEndDate,
			'currency': 'IDR',
			'preferred_price_range': datalayerAdd?.preferredPriceRange, // provide in floating data type [500000000.00, 1000000000.00]
			'preferred_baths_range': datalayerAdd?.preferredBathsRange, // provide in integer data type: [1, 2] 'preferred_bed_range': 'bed range', // provide in integer data type: [2, 3]
			'property_type': projectCategories // example value: 'apartment' or 'condo' or 'house' or 'land' or 'manufactured' or 'other' or' townhouse'
		}

		return value
	}


	useEffect(() => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push(dataLayerValue())
	}, [])

	return (
		<Layout path={location.pathname} lang={lang} theme={bgColor || "light"}>
			<Helmet>
				<link
					rel="amphtml"
					href={redirectAmpHtml.replace("/development/", "/development/amp/")}
				/>
				<body className="bd-posts" />
			</Helmet>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={location.href}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
				schema={schemaMarkup}
			/>
			<MicrodataProject
				title={seo.title}
				description={seo.metaDesc}
				url={location.href}
				image={SEODefaultImg}
				priceRange={datalayerAdd?.preferredPriceRange}
			/>
			<ProjectDetailsDataProject data={data.cpi} />
			<GlobalCover breadcrumb={location.pathname} data={data.cover} theme={bgColor || "light"} />
			<ProjectDetailsHeadline
				data={data.headline}
				slug={slug}
				title={data.cover?.title}
				location={location}
				eBrochures={eBrochures}
				city={projectLocations}
				lang={lang}
				datalayerAdd={datalayerAdd}
			/>
			<ProjectDetailsGallery data={data.gallery} />
			<ProjectDetailsTenants data={data.tenants} location={location.pathname} lang={lang} />
			<ProjectDetailsInfrastructure data={data.infrastructure} />
			<ProjectDetailsVirtualTour data={data.virtualTour} />
			<YoutubeFrame link={data.video} thumbnail={data.videoThumbnail} />
			<Map className="google-maps" lat={latitude} long={longitude} iframeMapScript={iframeMapScript}/>

			<ProjectDetailsLocation data={data.location} />
			<ProjectDetailsNearby data={nearbyData} loc={pageContext?.data?.projectLocations?.nodes[0]?.name} path={location.pathname} slug={slug} />
			<GlobalMarketingGallery data={data.marketingGallery} />
			<ProjectDetailsLeadForm
				lang={lang}
				cpi={data.cpi}
				title={data.cover?.title}
				utmId={utmId}
				slug={slug}
				city={projectLocations}
				propertyType={projectCategories}
				datalayerAdd={datalayerAdd}
			/>
			<NavBottom
				lang={lang}
				prevTitle={coverBottom.prev?.title}
				prevImg={coverBottom.prev?.image}
				prevLink={`${coverBottom.prev?.path}`}
				nextTitle={coverBottom.next?.title}
				nextImg={coverBottom.next?.image}
				nextLink={`${coverBottom.next?.path}`}
			/>

			<script type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: data.customMarkup }}
			/>


		</Layout>
	)
}

export default WithLocation(ProjectDetails)

